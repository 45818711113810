import React from "react"

import Layout from "../components/layout"
import Rechner from "../components/rechner/index"
import SEO from "../components/seo"
import { Header } from "../components/header"
import { Container } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header title={["Wann lohnt sich ein ", "Elektro-Auto?"]} />
    <Container fluid="lg">
      <Rechner />
    </Container>
  </Layout>
)

export default IndexPage
